import * as React from "react"
const LogoOnly = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150.958}
    height={200}
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 113.219 150"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M190 97h54.125v128.145H190Zm0 0" />
      </clipPath>
      <clipPath id="b">
        <path d="M130.875 75.145H185V200h-54.125zm0 0" />
      </clipPath>
    </defs>
    <path
      fill="#042e19"
      fillRule="evenodd"
      d="M87.992 94.602h-7.004c.719 2.16 2.16 3.808 4.32 5.25-4.93-4.536-3.902-4.844 2.684-5.25zm0 0"
    />
    <g clipPath="url(#a)" transform="translate(-130.89 -75.145)">
      <path
        fill="#042e19"
        fillRule="evenodd"
        d="M190.066 97.816v18.895a.729.729 0 0 1 0 .684v1.292c1.243 1.208 3.442 3.891 1.446 6.051.617 0 1.539 1.649 0 2.473 3.183 2.16 16.57-1.754 19.867-3.293 3.805-1.852 6.586-1.242 9.781 1.238.313.313.719.516 1.027.621v-.215c.106-.203.31-.308.418-.203.204 0 .204.11.31.204l.108.312c.407-.11.512-.719 0-1.957 2.364.93 2.051 3.293.512 4.117l5.356 19.055 6.074 21.523v.203c-1.442 5.559-6.383 9.582-12.457 9.582-5.965 0-11.012-4.023-12.348-9.582v-.214l5.965-21.524 5.355-19.055c-1.335-.515-2.05-1.957-3.398-2.578-1.754-.715-3.293-.715-5.25.512-5.453 3.5-14.727 7.723-21.312 6.387 0 0 .515 1.847-1.028 2.886.516 0 1.543 1.754-.414 2.47v18.585c.512 4.34 1.145 8.18 1.645 9.738 0 0 .93 1.754-.93 1.852 1.133.105 2.266 1.648.203 2.672 0 0 7.004 4.43-.105 10.406 1.027 0 2.468 1.957 0 2.781l9.054-.109v3.496h-4.937v2.887h-3.496s-1.754 5.562 16.976 5.36v1.237l8.961.934v1.848h-27.383v25.695l54.043.063V97.773Zm0 0"
      />
    </g>
    <path
      fill="#042e19"
      fillRule="evenodd"
      d="m91.285 53.094-5.344 19.054-5.87 21.118h11.214zM97.36 72.148l-5.348-19.054v40.172h11.218zm0 0"
    />
    <g clipPath="url(#b)" transform="translate(-130.89 -75.145)">
      <path
        fill="#556048"
        fillRule="evenodd"
        d="M157.43 198.383v-.824l9.058-.93v-1.242c18.735.203 16.98-5.356 16.98-5.356h-3.495v-2.886h-4.942v-3.497l8.961.106c-2.363-.824-.93-2.781 0-2.781-7.004-5.977 0-10.403 0-10.403-2.062-1.027-.93-2.578.203-2.675-1.957-.106-.93-1.848-.93-1.848.481-1.606 1.122-5.602 1.65-10.082V137.71c-1.958-.719-1.028-2.469-.419-2.469-1.648-1.027-1.133-2.89-1.133-2.89-6.492 1.34-15.754-2.887-21.207-6.383-1.957-1.239-3.496-1.239-5.355-.512-1.23.621-1.957 2.063-3.403 2.578l5.36 19.055 6.074 21.523v.203c-1.445 5.559-6.492 9.582-12.457 9.582-5.969 0-11.016-4.023-12.457-9.582v-.214L146 147.09l5.36-19.055c-1.446-.824-1.755-3.2.617-4.117-.618 1.238-.512 1.848 0 1.957l.109-.312c0-.204.203-.31.418-.204.105 0 .203.11.203.204l.106.203c.308-.106.62-.309 1.027-.621 3.183-2.47 5.965-3.09 9.781-1.239 3.297 1.54 16.684 5.465 19.762 3.293-1.54-.824-.621-2.469.105-2.469-2.148-2.234.239-4.996 1.446-6.14v-1.121a.743.743 0 0 1 0-.824V75.18l-54.043-.035V199.41l26.562-.035v-.992zm0 0"
      />
    </g>
    <path
      fill="#556048"
      fillRule="evenodd"
      d="m33.129 93.266-5.871-21.118-5.348-19.054v40.172zM52.887 48.977c-.086-1.114.363-2.418 1.136-3.883v-.301c-1.273 1.305-1.765 2.738-1.136 4.184zM17.785 94.602h-6.898c.62 2.16 2.054 3.808 4.222 5.25-4.832-4.536-3.914-4.844 2.676-5.25zM51.457 102.227c-.203-2.16.309-3.915 1.336-5.465-1.754 1.445-2.16 3.293-1.336 5.465zM21.184 53.094l-5.348 19.054-5.977 21.118h11.325Zm0 0"
    />
  </svg>
)
export default LogoOnly;
